<template>
  <div class="article-page">
    <img src="../../assets/image/article-banner.png" style="width: 100%" />
    <div class="article-list-container">
      <div class="article-category">
        {{ category.name }}
      </div>
      <template v-if="courtyList.length">
        <ul v-loading="Loading" :style="'height:' + pageSize * 46 + 'px'">
          <li
            class="article-item"
            v-for="(item, index) in courtyList"
            :key="index"
          >
            <router-link :to="`/article-detail/${item.id}`">
              <span style="float: left" class="article-title">
                {{ item.title }}
              </span>
              <span style="float: right">{{ item.createTime }}</span>
            </router-link>
          </li>
        </ul>
        <div style="padding: 20px 15px">
          <pagination
            @setPage="currentPageChange"
            :currentPage="pageNum"
            :total="total"
            :pageSize="pageSize"
          ></pagination>
        </div>
      </template>
      <template v-else>
        <div style="text-align: center; padding: 30px 0px; color: #999">
          暂无数据
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { courtyIofo } from '../../utils/coupon.js'
export default {
  name: 'articlePage',
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 5,
      category: {
        id: '',
        name: ''
      },
      courtyList: [],
      Loading: false,
      id: ''
    }
  },
  created() {
    this.category = this.$route.params
    // this._getList()
    // this._gettopList()
    this._getId()
  },
  watch: {
    $route: {
      handler(val) {
        this.category = val.params
        this._getId()
      }
    }
  },
  methods: {
    _getId() {
      const paramed = {
        id: this.$route.params.id
      }
      if (paramed.id === '9' || paramed.id === '10' || paramed.id === '11') {
        this._gettopList()
      } else {
        this._getList()
      }
    },
    _getList() {
      const params = {
        nationType: this.$route.params.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (params.nationType === '8') {
        this.Loading = true
        this.$reqPost(courtyIofo.cheapList, params).then((res) => {
          this.Loading = false
          if (res.data.code === 200) {
            this.courtyList = res.data.data.list
            this.total = res.data.data.total
            this.pageSize = res.data.data.pageSize
          }
        })
      } else {
        this.Loading = true
        this.$reqPost(courtyIofo.list, params).then((res) => {
          this.Loading = false
          if (res.data.code === 200) {
            this.courtyList = res.data.data.list
            this.total = res.data.data.total
            this.pageSize = res.data.data.pageSize
          }
        })
      }
    },
    _gettopList() {
      const param = {
        otherType: this.$route.params.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.Loading = true
      this.$reqPost(courtyIofo.topList, param).then((res) => {
        this.Loading = false
        if (res.data.code === 200) {
          this.courtyList = res.data.data.list
          this.total = res.data.data.total
          this.pageSize = res.data.data.pageSize
        }
      })
    },
    currentPageChange(pageNum) {
      this.courtyList = ''
      this.pageNum = pageNum
      this._getId()
    }
  }
}
</script>
<style lang="less" scoped>
.article-list-container {
  background: #fff;
  position: relative;
  top: -30px;
  border-radius: 15px;
  .article-category {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
  }
  .article-item {
    font-size: 16px;
    border-bottom: 1px solid #dedede;
    margin: 0px 15px;
    padding: 12px 0px;
    color: #6d6d6d;
    a {
      overflow: hidden;
      display: block;
    }
    .article-title {
      width: calc(100% - 150px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
